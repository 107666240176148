import { template as template_0054d3ced6f0485f9515332872c2641a } from "@ember/template-compiler";
const FKControlMenuContainer = template_0054d3ced6f0485f9515332872c2641a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
