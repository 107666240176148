import { template as template_1a43bc37cc104c8f880ea6fa502f96db } from "@ember/template-compiler";
const SidebarSectionMessage = template_1a43bc37cc104c8f880ea6fa502f96db(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
